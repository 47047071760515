.access-denied-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.access-denied-message {
    text-align: center;
    font-size: 20px;
}
