@import 'colors';

@font-face {
  font-family: 'Frutiger';
  src: url('../public/font/FrutigerLTProRoman.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Frutiger';
  src: url('../public/font/FrutigerLTProBold.otf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Frutiger';
  src: url('../public/font/FrutigerLTProLight.otf') format('truetype');
  font-weight: 300;
  /* or another appropriate weight for light */
  font-style: normal;
}
@font-face {
  font-family: 'Frutiger';
  src: url('../public/font/FrutigerLTProBlack.otf') format('truetype');
  font-weight: 800;
  /* or another appropriate weight for black */
  font-style: normal;
}

body {
  font-family: 'Frutiger', sans-serif;
}

button,
a {
  min-width: unset !important;
}

.panel {
  padding: 40px;
  border-radius: 10px;
  background: #EFEFEF;

  h2 {
    color: #414140;
    font-weight: bold;
    font-size: 24px;
    margin: 0;
    margin-bottom: 10px;
  }

  img {
    width: 100%;
    border-radius: 5px;
  }
}

p {
  margin: 0;
  font-weight: 300;
  font-size: 14px;
  color: #606060;
  line-height: 150%;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.btn {
  font-family: "Frutiger", sans-serif !important;
  font-weight: bold !important;
  text-transform: none !important;
  letter-spacing: 0 !important;
  border-radius: 30px !important;
  padding: 8px 30px !important;
  background: transparent !important;
  color: white !important;
  height: fit-content;
  font-size: 14px !important;
  flex-shrink: 0;
  box-sizing: border-box !important;
  min-height: 40px;

  svg {
    margin-top: -3px;
    font-size: 20px !important;
    width: 20px;

    &.loader {
      animation: spin 1s linear infinite;
      min-height: unset;
    }
  }

  &.btn-grey {
    background: #606060 !important;
    color: white;

    &:hover {
      background: darken(#606060, 4%) !important;
    }
  }

  &.btn-red {
    background: #F45051 !important;
    color: white;

    &:hover {
      background: darken(#F45051, 4%) !important;
    }
  }

  &.btn-white {
    background: white !important;
    color: #606060 !important;

    &:hover {
      background: darken(white, 4%) !important;
    }

    svg {
      color: $green-800 !important;
      margin-right: 0 !important;
    }
  }

  &.btn-icon {
    width: 40px;
    height: 40px;
    padding: 0 !important;
    display: flex;
    justify-content: center;
    align-content: center;

    &.btn-small {
      width: 34px !important;
      height: 34px !important;
      padding: 0 !important;

      svg {
        font-size: 16px !important;
      }
    }

    svg {
      margin: 0;
    }
  }

  &.btn-icon-2 {
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    padding: 0 !important;
    border-radius: 5px !important;

    svg {
      font-size: 14px !important;
      margin: 0;
    }
  }

  &.btn-border {
    color: white !important;
    background: transparent !important;
    border: 1px solid white !important;
  }

  &.btn-border-green {
    color: $creditlife-green !important;
    background: transparent !important;
    border: 1px solid $creditlife-green !important;
  }

  &.btn-grey {
    background: #DDD !important;
    color: #606060 !important;

    &:hover {
      background: darken(#DDD, 4%) !important;
    }

    svg {
      color: #999 !important;
    }
  }

  &.btn-block {
    width: 100%;
  }

  &.Mui-disabled {
    opacity: 0.7;
    cursor: not-allowed !important;
  }

  &.btn-small {
    font-size: 12px !important;
    padding: 8px 12px 5px 12px !important;
  }

  &.btn-full-width {
    width: 100%;
  }


  /// NEW BUTTON VARIANTS
  &.btn-green {
    background: $green-500 !important;
    color: $green-800 !important;

    &:hover {
      background: darken($creditlife-green, 4%) !important;
    }

    &:disabled {
      background: $grey-200 !important;
      color: $grey-500 !important;
    }
  }

  &.btn-warning {
    background: $yellow-20 !important;
    color: $dark-orange !important;

    &:hover {
      background: $yellow-30 !important;
    }
  }

  &.btn-ghost {
    background: transparent !important;
    color: $grey-700 !important;

    &:hover {
      background: $green-10 !important;
      color: $green-700 !important;
    }
  }

  &.btn-destructive {
    background: $red !important;
    color: white !important;

    &:hover {
      background: $dark-red !important;
    }

    &:disabled {
      background: $grey-200 !important;
      color: $grey-400 !important;
    }
  }

  &.btn-secondary {
    background: $green-20 !important;
    color: $green-800 !important;

    &:hover {
      background: $green-10 !important;
    }

    &:disabled {
      background: $grey-100 !important;
      color: $grey-400 !important;
    }
  }
}

.swal2-confirm {
  @extend .btn;
  @extend .btn-green;
  box-shadow: none !important;
}

.swal2-deny {
  @extend .btn;
  @extend .btn-destructive;
  box-shadow: none !important;
}

.swal2-cancel {
  @extend .btn;
  @extend .btn-ghost;
  box-shadow: none !important;
}

.btn-delete {
  background: #F45051 !important;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  color: white !important;
  justify-content: center;
}

.btn-delete-custom {
  @extend .btn-delete;
  height: fit-content;
  width: auto;
}

.heading {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  svg {
    margin-top: -3px;
    color: $creditlife-green;
    font-size: 30px;
    margin-right: 10px;
  }

  h1,
  h2 {
    font-weight: bold;
    color: #414140;
    margin: 0;
  }

  h1 {
    font-size: 26px;
  }

  h2 {
    font-size: 22px;
  }

  &.small {
    svg {
      font-size: 27px;
    }

    h2 {
      font-weight: bold;
      color: #414140;
      font-size: 18px;
      margin: 0;
    }
  }
}

:focus {
  outline-color: transparent !important;
  outline-style: none !important;
}

span.required {
  color: #F45051;
}

input[type=text],
input[type=number],
input[type=password],
input[type=email],
input[type=tel],
input[type=date],
select,
textarea {
  font-family: "Frutiger", sans-serif;
  border-radius: 10px;
  background: white;
  box-shadow: 7px 7px 25px 0px rgba(0, 0, 0, 0.10);
  padding: 17px 20px 15px 20px;
  font-size: 14px;
  color: #606060;
  border: 0;
  display: block;
  width: -webkit-fill-available;

  &::placeholder {
    color: #999;
    font-weight: 300;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed !important;
  }
}

select {
  -webkit-appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, rgba(0, 0, 0, 0.4) 50%),
    linear-gradient(135deg, rgba(0, 0, 0, 0.4) 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(50%),
    calc(100% - 15px) calc(50%),
    100% 0;
  background-size: 5px 5px,
    5px 5px,
    2.5em 2.5em;
  background-repeat: no-repeat;
}

.password-strength {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #606060;
  font-weight: 300;

  span {
    &.valid {
      color: #48BC5A;
      ;
    }

    &.not-valid {
      color: #F45051;
    }

    svg {
      margin-right: 5px;
      font-size: 16px;
      margin-top: 0;
    }
  }
}

.container {
  padding: 0 150px;

  @media(max-width: 768px) {
    padding: 0 30px;
  }
}

.icon-input {

  &:before,
  &:after {
    display: none !important;
  }

  input {
    box-shadow: none;
    border-radius: 0;
    padding-left: 5px !important;
  }

  border-radius: 10px;
  background: white;
  box-shadow: 7px 7px 25px 0px rgba(0, 0, 0, 0.10);
  overflow: hidden;
  padding-left: 20px;
  width: 100%;

  svg {
    color: $creditlife-green;
  }
}

.modal-content {
  box-shadow: 7px 7px 25px 0px rgba(0, 0, 0, 0.10);
  background: white;
  padding: 40px;
  border-radius: 10px;
  min-width: 300px;
  position: absolute;
  max-height: 80vh;
  overflow-y: scroll;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  h3 {
    margin: 0;
    margin-bottom: 20px;
  }
}

label {
  color: #414140;
  font-size: 13px;
  display: block;
  margin-bottom: 5px;
}

.quill {
  border: 0;
  box-shadow: 7px 7px 25px 0px rgba(0, 0, 0, 0.10);
  background: white;
  border-radius: 10px;
  overflow: hidden;

  .ql-container {
    border: 0 !important;
    height: 200px;
  }

  .ql-toolbar {
    border: 0 !important;
    background: #EFEFEF;
  }
}

.MuiDataGrid-cell,
.MuiDataGrid-columnHeader {
  font-family: "Frutiger", sans-serif !important;
}

.MuiDataGrid-cell:focus,
.MuiDataGrid-cell:focus-within,
.MuiDataGrid-columnHeader:focus,
.MuiDataGrid-columnHeader:focus-within {
  outline: none !important;
}

.MuiDataGrid-columnHeader .MuiDataGrid-columnHeaderTitle {
  font-weight: bold;
}

.MuiDataGrid-root {
  border: 0 !important;
}

.MuiListItem-root {
  font-size: 14px;
  color: #606060;

  &.selected {
    background: rgba(186, 214, 9, 0.2);
  }

  .MuiListItemButton-root {
    display: flex;
    justify-content: space-between;
    max-width: 100%;

    span {
      word-wrap: break-word;
      overflow-wrap: break-word;
      flex-grow: 1;
      flex-shrink: 1;
      min-width: 0;
    }

    input[type="checkbox"] {
      display: none;

      &+label {
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgba(0, 0, 0, 0.1);
        font-size: 16px;
        margin-bottom: 0;
        width: 25px;
        height: 25px;
        border-radius: 50px;
        flex-shrink: 0;
        background: rgba(0, 0, 0, 0.1);
        cursor: pointer;
      }

      &:checked+label {
        background: $creditlife-green;
        color: white;
      }
    }
  }
}

.list-item-buttons {
  display: flex;
  margin-left: 10px;
  flex-shrink: 0;
}

.list-item-button-action {
  width: 25px !important;
  height: 25px !important;
  min-width: unset !important;
  color: #606060 !important;
  background: rgba(0, 0, 0, 0.05) !important;
  border-radius: 30px !important;
  padding: 0 !important;
  font-size: 12px !important;
  flex-shrink: 0 !important;

  &.delete {
    background: #F45051 !important;
    color: white !important;
  }

  &.green {
    background: $creditlife-green !important;
    color: white !important;
  }
}

.editing-list {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 8px 16px;
  background: rgba(186, 214, 9, 0.2);

  input {
    all: unset;
    flex-grow: 1;
    margin-right: 10px;
  }
}

.list-add {
  display: flex;
  margin-top: 20px;

  input {
    all: unset;
    flex-grow: 1;
    background: rgba(0, 0, 0, 0.06);
    padding: 8px 16px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    font-size: 14px;
  }

  button {
    background: $creditlife-green !important;
    min-width: unset !important;
    height: 40px !important;
    width: 40px !important;
    border-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    color: white;
    font-size: 20px !important;
  }
}

.inline-alert-triangle {
  color: #F45051;
  margin-bottom: -2px;
  margin-right: 5px;
}

.pagination {
  display: flex;
  justify-content: center;

  .MuiPaginationItem-root {
    color: #606060;
    width: 32px;
  }
}

.MuiAlert-root {
  font-family: "Frutiger", sans-serif;
}

.MuiTypography-root {
  font-family: "Frutiger", sans-serif !important;
}

.backoffice-search {
  background: #efefef;
  border: 0;
  padding: 13px 20px 11px 20px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 40px;
  font-family: "Frutiger", sans-serif;
  font-size: 14px;
  line-height: 14px;
}

.backoffice-filters-search-bar {
  margin-bottom: 30px;

  select {
    background: #efefef;
    box-shadow: none;
    border-radius: 40px;
    width: 100%;
    box-sizing: border-box;
    padding: 15px 20px 12px 20px;
    font-family: "Frutiger", sans-serif;
    font-size: 14px;
    line-height: 14px;

    background-image: url("data:image/svg+xml;utf8,<svg fill='grey' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: calc(100% - 10px);
    background-position-y: 8px;
  }

  input {
    background: #efefef;
    border: 0;
    padding: 13px 20px 11px 20px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 40px;
    font-family: "Frutiger", sans-serif;
    font-size: 14px;
    line-height: 14px;
  }
}

.status-chip {
  font-family: "Frutiger", sans-serif;
  color: white !important;
  border-radius: 5px !important;
  font-size: 12px !important;
  font-weight: 600;
}


.loader {
  min-height: 700px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: $green-500;
  }
}


.MuiMenu-paper {
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.09) !important;
  min-width: 150px !important;

  .MuiButtonBase-root {
    font-family: "Frutiger", sans-serif !important;
    color: $grey-700;
    letter-spacing: normal;
    font-size: 13px;

    &.Mui-selected {
      background: $green-10;
      &:hover {
        background: $green-20 ;
      }
    }

    .MuiTouchRipple-root {
      display: none;
    }
  }
}

.backoffice-bulk-actions {
  display: flex;
  gap: 16px;
  align-items: center;
  font-size: $font-size-body-middle;
  color: $grey-600;
}

.backoffice-table {
  background: $grey-25;
  border-radius: 16px;

  .table-header .MuiTableCell-root {
    color: #212121;
    font-weight: bold;
  }

  .table-row {
    height: 10px;

    &:hover {
      background-color: #f4f4f4;
    }
  }

  td, th {
    padding-top: 10px;
    padding-bottom: 10px;
    color: $grey-700;
    font-family: "Frutiger", sans-serif;
    border-color: $grey-100;
  }

  th {
    padding-top: 14px;
    padding-bottom: 14px;
  }

  td.light {
    color: $grey-400;
  }

  td.status {
    svg {
      color: $grey-700;
      width: 15px;
      height: 15px;
      display: inline;
      vertical-align: sub;
    }
  }

  .MuiTableCell-root,
  .MuiTableSortLabel-root,
  .MuiInputBase-root {
    font-family: 'Frutiger', sans-serif;
  }

  .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows {
    font-family: 'Frutiger', sans-serif;
  }
}

.no-results-table-row {
  td {
    color: $grey-500;
    text-align: center;
  }
}

.password-input {
  font-family: "Frutiger", sans-serif;
  border-radius: 10px;
  background: white;
  box-shadow: 7px 7px 25px 0px rgba(0, 0, 0, 0.1);

  input {
    box-shadow: none;
    padding: 0;
    height: 49px;
    padding-left: 20px !important;

    &::placeholder {
      opacity: 1;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    display: none;
  }
}
