@import '../../colors';

.swal2-container {
  z-index: 2000;
}

.authentication-view{
  background-image: url('../../../public/default-header.png');
  background-repeat: no-repeat;
  background-size: 95%;
  min-height: 95vh;

  .logo{
    max-height: 50px;
  }

  .login-form{
    position: relative;
    overflow: hidden;

    .forgot-password{
      border: 0;
      background: transparent;
      cursor: pointer;
      padding: 0;
      font-family: "Frutiger", sans-serif;
      font-weight: bold;
      color: $creditlife-green;
      margin-top: 20px;
    }

    .auth-loading{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,0.3);
      display: flex;
      align-items: center;
      justify-content: center;

      svg{
        color: white;
      }
    }

    h1{
      color: #414140;
      margin: 0;
      font-size: 34px;
      text-align: center;

      span{
        color: $creditlife-green;
      }
    }

    p{
      text-align: center;
      margin-top: 10px;
      margin-bottom: 20px;
    }

    label{
      font-size: 14px;
      color: #414140;
      display: block;
      margin-bottom: 5px;

      &:not(:first-child){
        margin-top: 20px;
      }
    }

    .form-actions{
      margin-top: 30px;
      text-align: center;

      a{
        display: block;
        color: #414140;
        font-size: 14px;
        text-decoration: none;
        font-weight: 300;
        margin-top: 20px;

        b{
          color: $creditlife-green;
        }
      }
    }

    .error{
      color: #F45051;
      font-weight: bold;
      background: #FFCDCD;
      border-radius: 5px;
      padding: 15px 20px 12px 20px;
      display: flex;
      align-items: center;

      svg{
        margin-right: 10px;
        margin-top: -2px;
        font-size: 18px;
      }
    }
  }
}
