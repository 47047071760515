@import "../../colors";

.loading-screen{
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  color: #414140;
  padding-top: 30vh !important;

  div{
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    color: $creditlife-green;
    font-size: 60px;

    svg.loader{
      animation: spin 1s linear infinite;
    }
  }
}
