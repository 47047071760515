@import '../../../../colors';

.conversations-view {
  min-height: calc(100vh - 190px);
  font-family: 'Frutiger', sans-serif;

  .alert-icon {
    color: $orange;
    display: block;
    width: 16px;
  }

  .handled-icon {
    color: $green-500;
    display: block;
    width: 16px;
  }

  .table-header .MuiTableCell-root {
    color: #212121;
    font-weight: bold;
  }

  .table-row {
    height: 10px;
    cursor: pointer;

    &:hover {
      background-color: #f4f4f4;
    }
  }

  td, th {
    padding-top: 8px;
    padding-bottom: 8px;

    &.center {
      text-align: center;
    }
  }

  .satisfaction {
    display: inline;

    &.red {
      color: $red;
    }

    &.orange {
      color: $orange;
    }

    &.green {
      color: $green-500;
    }
  }

  .MuiTableCell-root,
  .MuiTableSortLabel-root,
  .MuiInputBase-root {
    font-family: 'Frutiger', sans-serif;
  }

  input[type=text] {
    padding: 17px 20px 15px 20px;
    font-size: 14px;
    color: #606060;
    border: 0;
    display: block;
    width: -webkit-fill-available;
    background: #EFEFEF;
    box-shadow: none;
    padding-top: 14px;
    padding-bottom: 10px;
    border-radius: 50px;
  }
}
