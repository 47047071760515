@import '../../../../colors';

.conversation-view {

  .MuiChip-root {
    background: $green-20;
    border-radius: 6px;
    margin-bottom: 6px;
    margin-right: 6px;
    font-family: "Frutiger", sans-serif;
    font-size: $font-size-body-small;
    color: $green-700;
  }

  .log {
    display: flex;

    &:last-child {
      margin-bottom: 30px;
    }

    .log-content {
      font-size: $font-size-body-middle;
      font-weight: normal;
      color: $grey-700;

      b {
        color: $green-700;
      }

      small {
        font-size: $font-size-body-small;
        color: $grey-400;
      }
    }

    .log-icon {
      width: 28px;
      flex-shrink: 0;
      position: relative;

      span {
        padding: 4px 0;
        background: white;
        position: relative;
        z-index: 2;
        color: $grey-400;

        svg {
          height: 16px;
          width: 16px;
        }
      }
    }

    &:not(:last-child) {
      .log-content {
        padding-bottom: 16px;
      }

      .log-icon {
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 8px;
          height: 100%;
          border-left: 1px dashed $grey-200;
        }
      }
    }
  }

  .assignment-label {
    display: inline-block;
    background: rgba(0,0,0,0.05);
    color: $grey-700;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: $font-size-body-small;
    font-weight: normal;
    margin-left: 10px;

    b {
      color: $dark-orange;
    }
  }

  .comment {
    margin-top: 16px;

    .comment-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $grey-400;
      font-size: $font-size-body-middle;

      .author {
        font-weight: bold;
        color: $green-700;
      }
    }

    p {
      margin-top: 6px !important;
    }
  }

  .new-comment {
    margin-top: 16px;

    label {
      font-weight: bold;
      color: $grey-700;
      font-size: $font-size-body-middle;
      margin-bottom: 10px;
    }

    textarea {
      background: $grey-50;
      box-shadow: none;
      padding: 16px;
      font-size: $font-size-body-middle;
    }
  }

  .conversation-panel {
    border: 1px solid $grey-200;
    border-radius: 16px;

    &:not(:first-child) {
      margin-top: 30px;
    }

    .panel-content {
      padding: 20px;

      p {
        font-weight: normal;
        color: $grey-700;

        &:not(:first-child) {
          margin-top: 10px;
        }

        a {
          text-decoration: underline;
          color: $grey-700;
        }

        &.satisfaction {
          span {
            font-weight: bold;

            &.red {
              color: $red;
            }

            &.orange {
              color: $orange;
            }

            &.green {
              color: $green-500;
            }
          }
        }
      }
    }

    .panel-header {
      padding: 20px;
      border-bottom: 1px solid $grey-200;
      background: $grey-50;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;

      h2 {
        margin: 0;
        font-size: $font-size-h1;
        color: $grey-700;
      }

      .timestamps {
        display: block;
        color: $grey-700;
        font-size: $font-size-body-small;
        margin-top: 10px;
      }
    }
  }

  .comments-title {
    margin: 0;
    color: $grey-700;

    .badge {
      display: inline-block;
      vertical-align: middle;
      line-height: 20px;
      text-align: center;
      height: 20px;
      min-width: 20px;
      border-radius: 20px;
      background: $grey-200;
      font-size: $font-size-body-small;
      margin-left: 8px;
    }
  }

  p.comments-placeholder {
    margin-top: 5px !important;
    color: $grey-400 !important;
  }

  .alert {
    background: $yellow-10;
    padding: 20px;
    border-radius: 16px;
    margin-top: 40px;
    margin-bottom: 10px;

    h4 {
      color: $dark-orange;
      font-size: $font-size-body-middle;
      font-weight: bold;
      display: flex;
      align-items: center;
      gap: 10px;
      margin: 0;
      min-height: 24.5px;

      svg {
        width: 14px;
        height: 14px;
        color: $orange;
      }
    }

    p {
      color: $grey-700;
      font-size: $font-size-body-middle;
      font-weight: 300;
      margin-top: 12px;
      margin-bottom: 12px;
    }

    .btn {
      color: $dark-orange !important;
    }
  }

  .chat {
    width: 100%;
    box-sizing: border-box;
    padding: 30px;
    border: 1px solid $grey-200;
    border-radius: 16px;
    background: $grey-50;
    height: 80vh;
    overflow-y: scroll;
  }
}
