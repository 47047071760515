@import '../../../colors';

.form-label {
  font-size: $font-size-body-middle;
  color: $grey-700;
  display: block;
  margin-bottom: 10px;
  font-weight: bold;

  .required {
    color: $red;
    margin-left: 3px;
  }
}
