@import '../../colors';

.users-view {
  .alert-icon {
    width: 20px;
    height: 20px;
    color: $red;
  }

  .table-select {
    width: 100%;
    padding: 6px 8px;
    border: 1px solid $grey-100;
    border-radius: 4px;
    background-color: white;
    color: $grey-600;
    font-size: $font-size-body-small;
    cursor: pointer;

    &:focus {
      outline: none;
      border-color: $grey-600;
    }

    &:hover {
      border-color: $grey-600;
    }
  }

  .btn-delete {
    padding: 0 !important;
    min-height: unset;
    font-size: $font-size-body-small !important;
    color: $grey-600;

    svg {
      width: 16px;
      height: 16px;
      margin-right: 0;
    }

    &:hover {
      background: transparent !important;
      color: $red !important;
    }
  }

  // Table styling matching the invitations view
  .backoffice-table {
    .MuiTableHead-root {
      .MuiTableCell-root {
        background-color: $grey-100;
        color: $grey-700;
        font-weight: bold;
      }
    }

    .MuiTableBody-root {
      .MuiTableRow-root {
        &:hover {
          background-color: $grey-25;
        }

        .MuiTableCell-root {
          color: $grey-600;
          border-bottom: 1px solid $grey-100;
        }
      }
    }
  }
}
