@import '../../../../colors';

.source-new-view {
  .online-info {
    color: $grey-700;
    width: 20px;
    position: relative;
    z-index: 2;
  }

  .topics-input {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid $grey-400;
    border-radius: 8px;
    padding: 8px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding-right: 30px;
    position: relative;
    cursor: pointer;
    margin-right: 10px;
    flex-grow: 1;

    .chevron {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      color: $grey-600;
    }

    span.placeholder {
      padding-left: 5px;
      color: $grey-600;
      font-size: $font-size-body-middle;
      display: inline-block;
      height: 32px;
      line-height: 32px;
    }

    span.chip {
      height: 32px;
      background: $green-20;
      color: $green-900;
      font-size: $font-size-body-middle;
      padding: 6px 12px;
      display: inline-flex;
      align-items: center;
      gap: 10px;
      border-radius: 4px;
      box-sizing: border-box;

      svg {
        width: 15px;
        height: 15px;
      }
    }
  }

  .source-dropzone {
    display: flex;
    padding: 10px 16px;
    align-items: center;
    gap: 20px;
    color: $grey-700;
    border-radius: 8px;
    border: 1px dashed $grey-400;
    padding-bottom: 9px;
    cursor: pointer;

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    svg {
      width: 20px;
      height: 20px;
    }

    h4 {
      margin: 0;
      font-size: $font-size-body-middle;
    }

    p {
      font-size: $font-size-body-small;
      line-height: $font-size-body-small;
      margin-top: 6px;
      font-weight: normal;
      color: $grey-600;
    }
  }

  .uploaded-file {
    background: $green-20;
    border-radius: 8px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .content {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .icon, button {
      width: 40px;
      height: 40px;
      background: $green-20;
      border-radius: 8px;
      color: $green-800;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;

      svg {
        height: 20px;
      }
    }

    button {
      background: $red-10;
      color: $red;
      cursor: pointer;
    }

    h4 {
      margin: 0;
      font-size: $font-size-body-middle;
      color: $green-700;
    }
  }

  .separator {
    width: 100%;
    position: relative;
    text-align: center;
    color: $grey-700;
    font-size: $font-size-body-middle;
    margin: 20px 0;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      height: 1px;
      width: calc(50% - 20px);
      background: $grey-100;
    }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      height: 1px;
      width: calc(50% - 20px);
      background: $grey-100;
    }
  }

  .loading-backdrop {
  color: #fff;
  z-index: 1301;
  background-color: rgba(0, 0, 0, 0.5);
  }
}
