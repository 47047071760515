.feedback-container {
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 1000;
  transition: 0.2s ease all;

  &.chat {
    right: 70px;
  }

  button {
    box-shadow: 7px 7px 25px 0px rgba(0, 0, 0, 0.1) !important;
  }
}
