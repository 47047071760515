@import '../../../colors';

.message {
  background: white;
  margin-top: 16px;
  border-radius: 8px;
  width: 80%;
  padding: 16px;
  color: $grey-700;
  font-size: $font-size-body-middle;

  .message-loader {
    margin-top: 10px;

    span {
      background: $green-800 !important;
    }
  }

  .source {
    background: $green-10;
    margin-top: 10px;
    border-radius: 8px;
    padding: 8px;
    display: flex;
    align-items: center;
    gap: 10px;
    text-decoration: none;

    .icon {
      width: 40px;
      height: 40px;
      background: $green-20;
      border-radius: 8px;
      color: $green-800;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;

      svg {
        height: 20px;
      }
    }

    h4 {
      margin: 0;
      font-size: $font-size-body-middle;
      color: $green-700;
    }
  }

  .sender {
    font-weight: bold;
    color: $green-700;
    display: block;
    margin-bottom: 8px;
  }

  p {
    font-weight: normal;
  }

  &.user {
    align-self: flex-end;
  }

  .message-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;

    .sources-button {
      display: flex;
      align-items: center;
      cursor: pointer;

      .counter {
        background: $green-700;
        width: 15px;
        height: 15px;
        color: white;
        text-align: center;
        line-height: 15px;
        font-size: 10px;
        font-weight: bold;
        border-radius: 20px;
        flex-shrink: 0;
        margin-right: 10px;
      }

      .icon {
        background: $green-20;
        border-radius: 8px;
        width: 34px;
        height: 34px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        margin-right: 5px;

        svg {
          color: $green-600;
          width: 20px;
        }
      }

      .chevron {
        color: $grey-700;
        width: 20px;
        height: 20px;
        margin-left: 5px;
      }
    }

    button {
      width: 40px;
      height: 40px;
      border-radius: 40px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border: 0;
      transition: 0.2s ease all;
      cursor: pointer;

      svg {
        width: 16px;
        height: 16px;
      }

      &.escalation {
        width: unset;
        padding: 0 20px;
        font-weight: bold;
      }

      &.positive {
        background: $green-20;
        color: $green-600;
        margin-right: 10px;

        &:hover {
          background: $green-10;
        }

        &.active {
          background: $green-500;
          color: white;

          &:hover {
            background: $green-600;
          }
        }
      }

      &.negative {
        background: $red-10;
        color: $red;

        &:hover {
          background: $red-20;
        }

        &.active {
          background: $red;
          color: white;

          &:hover {
            background: $dark-red;
          }
        }
      }

      &.sources {
        color: $green-700;
        background: $green-500;

        &:hover {
          background: $green-600;
        }
      }
    }
  }
}
