@import '../../../colors';

.backoffice-title {
  display: flex;
  align-items: center;
  flex-shrink: 0;

  .backoffice-title-icon {
    width: 40px;
    height: 40px;
    background-color: $green-20;
    color: $green-600;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
  }

  h1 {
    color: $grey-700;
    font-size: $font-size-h1;
    margin: 0;
  }
}
