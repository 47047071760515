@import '../../colors';

.match-ranking{
  color: #999;
  box-shadow: 7px 7px 25px 0px rgba(0, 0, 0, 0.10);
  background: #FFF;
  font-size: 10px;
  padding: 6px 15px 4px 15px;
  border-radius: 30px;
  position: absolute;
  left: 0;
  top: -50px;
}

p.risk{
  margin: 15px 0 !important;

  svg{
    font-size: 24px !important;
    margin-right: 5px;
  }

  img{
    vertical-align: middle;
    height: 24px;
    margin-right: 5px;
  }
}

.single-match{
  min-height: 74vh;

  h3{
    color: #606060;
    font-size: 16px;
    margin-top: 30px;
  }

  .header{
    width: calc(100% - 80px);
    margin-left: 30px;
    padding: 20px;
    background: $creditlife-green;
    box-shadow: 7px 7px 25px 0px rgba(0, 0, 0, 0.10);
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: -160px;
    z-index: 2;
    position: relative;

    .image{
      width: 70px;
      height: 70px;
      border-radius: 50%;
      box-shadow: 7px 7px 25px 0px rgba(0, 0, 0, 0.10);
      background-size: cover;
      background-position: center;
      margin-right: 20px;
    }

    .match-info{
      display: flex;
      align-items: center;

      h1{
        color: white;
        margin: 0;
        font-size: 24px;
      }

      h2{
        margin: 0;
        color: rgba(255, 255, 255, 0.80);
        font-size: 14px;
        margin-top: 3px;
      }
    }

    .match-buttons{
      button{
        margin: 0 10px;
      }
    }
  }

  .left-column{
    border-radius: 20px;
    background-image: url('../../../public/bubbles.jpg');
    background-size: cover;
    background-position: bottom;
    box-shadow: 7px 7px 25px 0px rgba(0, 0, 0, 0.10);
    height: calc(100% - 50px);
    z-index: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 30px;
    text-align: center;
    min-height: 60vh;

    p{
      a{
        color: $creditlife-green;
        text-decoration: none;
        font-weight: bold;
      }
    }

    img{
      max-height: 40px;
      margin-top: 30px;
    }

    a.small{
      color: #606060;
      font-size: 10px;
      display: block;
      text-decoration: none;
      margin-top: 7px;
    }
  }

  .case-details {
    margin-top: 170px;

    p {
      margin: 20px 0;

      svg{
        color: $creditlife-green;
        vertical-align: middle;
        margin-top: -2px;
        font-size: 16px;
      }
    }

    ul, li {
      color: inherit;
      font-size: inherit;
      font-family: inherit;
      line-height: inherit;
    }
  }
}

.alternatives-modal{
  width: 100%;
  max-width: 800px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .alternative-match{
    border-radius: 10px;
    background: #FFF;
    padding: 40px;
    box-shadow: 7px 7px 25px 0px rgba(0, 0, 0, 0.10);
    height: calc(100% - 80px);
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .image{
      width: 70px;
      height: 70px;
      border-radius: 50%;
      box-shadow: 7px 7px 25px 0px rgba(0, 0, 0, 0.10);
      background-size: cover;
      background-position: center;
      margin-bottom: 15px;
    }

    h3{
      margin: 0;
      color: #414140;
      font-size: 18px;
    }

    h4{
      color: rgba(96, 96, 96, 0.80);
      font-size: 13px;
      margin: 0;
      margin-top: 10px;
    }

    .match-ranking{
      top: -20px;
      left: 10px;
    }
  }
}
