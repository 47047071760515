@import '../../../colors';

.form-panel {
  border: 1px solid $grey-200;
  border-radius: 16px;
  font-size: $font-size-body-middle;
  color: $grey-700;

  .form-panel-header {
    background: $grey-50;
    border-bottom: 1px solid $grey-200;
    padding: 20px;
    color: $grey-700;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;

    h2 {
      font-size: $font-size-h2;
      margin: 0;
    }

    h3 {
      font-size: $font-size-body-small;
      margin: 0;
      font-weight: normal;
      margin-top: 8px;
    }
  }

  .form-panel-body {
    padding: 20px;
  }
}
