@import '../../../../colors';

.test-result-detail-view {
  padding-bottom: 2rem;
  padding-top: 2rem;

  .content-container {
    padding: 2rem;
    background: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }

  .header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    gap: 2rem;

    .score-badge {
      padding: 6px 12px;
      border-radius: 6px;
      font-weight: 600;
      font-size: $font-size-body-middle;

      &.high-score {
        background-color: rgba($green-600, 0.1);
        color: $green-700;
      }

      &.low-score {
        background-color: rgba($red, 0.1);
        color: $red;
      }
    }

    .btn-outline {
      border: 1px solid $grey-200;
      color: $grey-700;
      padding: 8px 16px;
      border-radius: 8px;
      font-size: $font-size-body-middle;

      &:hover {
        background-color: $grey-50;
      }
    }
  }

  .section {
    margin-bottom: 2rem;

    h3 {
      color: $grey-700;
      font-size: $font-size-h3;
      margin-bottom: 0.5rem;
      font-weight: 600;
    }

    .content-box {
      background-color: $grey-50;
      border: 1px solid $grey-200;
      border-radius: 8px;
      padding: 1rem;
      min-height: 100px;
    }
  }

  .section-row {
    display: flex;
    gap: 2rem;
    margin-bottom: 2rem;

    .section {
      flex: 1;
      margin-bottom: 0;
    }
  }

  .sources-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 6px;
    transition: background-color 0.2s;

    &:hover {
      background-color: $grey-100;
    }

    .counter {
      background-color: $grey-200;
      color: $grey-700;
      padding: 2px 8px;
      border-radius: 12px;
      font-size: $font-size-body-small;
      font-weight: 500;
    }

    .icon {
      color: $grey-600;
    }

    .text {
      color: $grey-700;
      font-size: $font-size-body-middle;
    }

    .chevron {
      margin-left: auto;
      color: $grey-600;
    }
  }

  .sources-list {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .source-item {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.5rem;
      border-radius: 6px;
      cursor: pointer;
      color: $grey-700;
      transition: background-color 0.2s;

      &:hover {
        background-color: $grey-100;
      }

      .source-icon {
        color: $grey-600;
      }
    }
  }

  .similarity-section {
    .similarity-score {
      font-size: $font-size-h2;
      font-weight: 600;
      color: $green-700;
      text-align: center;
      padding: 1rem;
    }
  }

  .answers-section {
    .section {
      flex: 1;

      .content-box {
        min-height: 200px;
      }
    }
  }

  .no-sources {
    color: $grey-600;
    font-style: italic;
  }

  .btn {
    padding: 8px 16px;
    border-radius: 8px;
    font-size: $font-size-body-middle;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    transition: background-color 0.2s;

    &.btn-green {
      background-color: $green-600;
      color: white;

      &:hover {
        background-color: $green-700;
      }
    }

    &.btn-ghost {
      background: transparent;
      color: $grey-700;
      border: 1px solid $grey-400;

      &:hover {
        background: $grey-50;
      }
    }
  }
}
