@import '../../colors';

.chatbot-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;

  button {
    box-shadow: 7px 7px 25px 0px rgba(0, 0, 0, 0.10);
  }

  .chat-window {
    width: 300px;
    height: 400px;
    background-color: $grey-50;
    box-shadow: 7px 7px 25px 0px rgba(0, 0, 0, 0.10);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 20px 12px;
    box-sizing: border-box;
    gap: 10px;
    border: 1px solid $grey-200;

    .chat-header {
      flex-shrink: 0;
      background: red;
      margin-left: -12px;
      margin-right: -12px;
      margin-bottom: -10px;
      margin-top: -20px;
      background: $grey-200;
      border-bottom: 1px solid $grey-200;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px;
      font-size: $font-size-body-middle;
      font-weight: bold;
      color: $grey-700;

      .actions {
        display: flex;
        align-items: center;
        gap: 6px;
      }

      button {
        all: unset;
        cursor: pointer;

        svg {
          width: 20px;
          height: 20px;
          color: $grey-700;
        }
      }
    }

    .messages-container {
      flex-grow: 1;
      overflow-y: auto;
      display: flex;
      flex-direction: column-reverse;

      .message {
        padding: 10px;
        border-radius: 8px;
        background-color: white;
        font-size: $font-size-body-small;
        color: $grey-700;
        width: calc(100% - 70px);

        &.chatbot {
          border-bottom-left-radius: 0;
        }

        &.user {
          border-bottom-right-radius: 0;
          margin-left: auto;
          align-self: flex-end;
          font-weight: bold;
        }

        .message-sender {
          color: $green-700;
          font-weight: bold;
          display: block;
          margin-bottom: 5px;
        }

        .sources {
          display: flex;
          align-items: center;
          margin-top: 8px;
          cursor: pointer;

          .sources-count {
            background: $green-700;
            width: 15px;
            height: 15px;
            color: white;
            text-align: center;
            line-height: 15px;
            font-size: 10px;
            font-weight: bold;
            border-radius: 20px;
            flex-shrink: 0;
            margin-right: 5px;
          }

          .sources-icon {
            background: $green-20;
            border-radius: 8px;
            width: 28px;
            height: 28px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;

            svg {
              color: $green-600;
              width: 16px;
            }
          }

          .sources-text {
            margin-left: 5px;
          }
        }
      }
    }

    .message-input-container {
      display: flex;
      align-items: flex-end;
      gap: 10px;

      input {
        font-weight: normal;
        color: $grey-700;
        font-size: $font-size-body-small;
        padding: 14px 16px;
        box-shadow: none;
        border-radius: 8px;

        &::placeholder {
          color: $grey-400;
          font-weight: normal;
          font-size: $font-size-body-small;
        }
      }
    }
  }
}
