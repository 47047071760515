@import '../../colors.scss';

footer{
  background: #EFEFEF;
  padding: 20px 0;
  text-align: center;
  position: relative;

  .footer-link {
    position: absolute;
    top: 50%;
    color: $grey-700;
    font-size: 12px;
    text-decoration: none;

    &.footer-link-1 {
      right: 50%;
      transform: translate(-60px, -50%);
    }

    &.footer-link-2 {
      left: 50%;
      transform: translate(60px, -50%);
    }
  }

  .footer-logo {
    margin: 0 auto;
    display: block;

    img {
      max-height: 25px;
      display: inline-block;
    }
  }

  //.footer-content{
  //  display: flex;
  //  justify-content: space-between;
  //  align-items: center;
  //  color: #414140;
  //  font-size: 12px;
  //  position: relative;
  //
  //  a{
  //    text-decoration: none;
  //  }
  //
  //  .footer-content__left{
  //    a{
  //      font-weight: bold;
  //      color: $creditlife-green;
  //    }
  //    margin-top: 5px;
  //  }
  //
  //  .footer-content__logo{
  //    position: absolute;
  //    top: 0;
  //    left: 50%;
  //    transform: translateX(-50%);
  //
  //    img{
  //      max-height: 25px;
  //      display: block;
  //    }
  //  }
  //
  //  .footer-content__right{
  //    text-align: right;
  //    margin-top: 5px;
  //
  //    a{
  //      margin-left: 10px;
  //      color: #414140;
  //    }
  //  }
  //}
}
