@import '../../../colors';

.custom-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: $grey-700;

  svg {
    width: 15px;
    height: 15px;
    margin-right: 5px;
  }

  .checkbox {
    width: 14px;
    height: 14px;
    border-radius: 4px;
    border: 1px solid $grey-400;
    transition: 0.1s ease all;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    svg {
      margin-right: 0;
      color: white;
      visibility: hidden;
      opacity: 0;
      transition: 0.1s ease all;
    }
  }

  &:hover {
    .checkbox {
      background: $green-20;
      border-color: $green-600;
    }
  }
}

input:checked + .custom-checkbox {
  .checkbox {
    background: $green-600;
    border-color: $green-600;

    svg {
      visibility: visible;
      opacity: 1;
    }
  }

  &:hover {
    .checkbox {
      background: $green-500;
      border-color: $green-500;
    }
  }
}
