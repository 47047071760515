.topics-view {
    min-height: calc(100vh - 190px);
    font-family: 'Frutiger', sans-serif;

    .table-header .MuiTableCell-root {
        color: #212121;
        font-weight: bold;
    }

    .table-row {
        height: 10px;

        &:hover {
            background-color: #f4f4f4;
        }
    }

    .table-cell {
        padding-top: 8px;
        padding-bottom: 8px;
        vertical-align: middle;
    }

    .MuiTableCell-root,
    .MuiTableSortLabel-root,
    .MuiInputBase-root {
        font-family: 'Frutiger', sans-serif;
    }

    input[type=text] {
        padding: 17px 20px 15px 20px;
        font-size: 14px;
        color: #606060;
        border: 0;
        display: block;
        width: -webkit-fill-available;
        background: #EFEFEF;
        box-shadow: none;
        padding-top: 14px;
        padding-bottom: 10px;
        border-radius: 50px;
    }
}
