.best-matches-view {
    padding: 20px;
    max-width: 800px;
    margin: auto;

    h1 {
        text-align: center;
        margin-bottom: 20px;
    }

    .best-matches-list {
        .best-match-item {
            border: 1px solid #ddd;
            padding: 15px;
            border-radius: 8px;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
            margin-bottom: 20px;

            h3 {
                margin-top: 0;
                color: #333;
            }

            .details-button {
                background: none;
                border: none;
                cursor: pointer;
                color: #007bff; // Example color

                &:hover {
                    color: #0056b3; // Darker color on hover
                }

                svg {
                    width: 24px; // Size of the icon
                    height: 24px;
                }
            }
        }
    }
}
