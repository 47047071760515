$creditlife-green: #BAD60A;
$light-red: #EFB8A9;

$green-500: #BAD60A;
$green-10: mix(transparent, $green-500, 90%);
$green-20: mix(transparent, $green-500, 80%);
$green-600: #9AB108;
$green-700: #677605;
$green-800: #4D5904;
$green-900: #333B03;

$red: #AD3B24;
$red-10: rgba(#D73637, 0.1);
$red-20: rgba(#D73637, 0.2);
$dark-red: #8F2D1A;

$orange: #FFA200;
$orange-100: #FFECCC;
$dark-orange: #553600;
$orange-10: rgba(#FFA200, 0.1);
$orange-700: #B5770B;

$grey-700: #414140;
$grey-600: #606060;
$grey-500: #757575;
$grey-400: #999999;
$grey-200: #E3E3E3;
$grey-100: #EFEFEF;
$grey-50: #F5F5F5;
$grey-25: #FBFBFB;

$yellow-20: mix(transparent, #FFA200, 80%);
$yellow-30: mix(transparent, #FFA200, 70%);
$yellow-10: mix(transparent, #FFA200, 90%);


$font-size-h1: 26px;
$font-size-h2: 20px;
$font-size-h3: 16px;
$font-size-body-middle: 14px;
$font-size-body-small: 12px;
