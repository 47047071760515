@import '../../../colors';

.form-textarea {
  box-shadow: none;
  border-radius: 8px;
  border: 1px solid white;
  outline: 1px solid $grey-400;
  padding: 12px 16px;
  font-size: $font-size-body-middle;
  color: $grey-700;
  transition: 0.2s ease all;

  &::placeholder {
    color: $grey-500;
  }

  &:hover {
    outline: 1px solid $green-500;
  }

  &:focus {
    border: 1px solid $green-500 !important;
    outline: 1px solid $green-500 !important;
  }
}
