@import '../../../colors';

.status-switch {
  margin-right: 0;
  margin-bottom: 0;

  .MuiSwitch-root {
    margin-top: -10px;
    margin-bottom: -10px;
    padding: 13px 12px;

    .MuiButtonBase-root {
      .MuiSwitch-thumb {
        box-shadow: none;
        color: $red;
      }

      + .MuiSwitch-track {
        background: $red-10;
      }

      &.Mui-disabled {
        + .MuiSwitch-track {
          background: $grey-200;
        }

        .MuiSwitch-thumb {
          background: $grey-400;
        }
      }

      &.Mui-checked {
        + .MuiSwitch-track {
          background: $green-20;
        }

        .MuiSwitch-thumb {
          color: $green-500;
        }
      }
    }
  }

  .MuiTypography-root {
    font-size: $font-size-body-middle;
    letter-spacing: normal !important;
    color: $red;
  }

  &.online {
    .MuiTypography-root {
      color: $green-700;
    }
  }

  &.disable {
    .MuiTypography-root {
      color: $grey-500;
    }
  }
}
