@import '../../colors';

:root {
  --swal2-loader-color: #{$creditlife-green};
}

.swal2-loader {
  border-right-color: var(--swal2-loader-color) !important;
}

.swal2-styled.swal2-confirm.swal2-loading::after {
  border-color: var(--swal2-loader-color) transparent var(--swal2-loader-color) transparent !important;
}

.circle-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
}

.circle-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.profession-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &:not(:first-child) {
    padding-top: 10px;
  }

  &:not(:last-child) {
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
  }

  .profession-name {
    color: #606060;
    font-size: 14px;
  }

  .buttons-container {
    display: flex;
    align-items: center;
    gap: 10px;

    button {
      width: 100px;
    }
  }

    .mock-check {
      color: rgba(0, 0, 0, 0.1);
      font-size: 16px;
      width: 25px;
      height: 25px;
      border-radius: 50px;
      background: rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;

      &.checked {
        background: $creditlife-green;
        color: white;
      }
    }
  }

  .generated-case {
    padding: 30px;
    margin-top: 30px;

    .case-content {
      color: #606060;
      margin-top: 20px;
      font-size: 14px;
    }

    .case-header {
      display: flex;
      justify-content: space-between;

      .case-info {
        display: flex;
        align-items: center;

        .case-heading {
          margin-left: 20px;

          h3 {
            margin: 0;
            color: #1a1a1a;
          }

          span {
            color: #606060;
            font-size: 13px;
          }
        }
      }
    }
  }

  footer {
    margin-top: auto;
  }
