@import "../../colors";

.case-content-dashboard{
  .risk{
    &:not(:first-child){
      padding-top: 20px;
    }

    &:not(:last-child){
      padding-bottom: 20px;
      border-bottom: 1px solid #ddd;
    }

    .input-row{
      display: flex;

      input{
        padding: 6px 15px 4px 15px;
      }

      button{
        margin-left: 10px;
        flex-shrink: 0;
      }
    }

    .risk-toolbar{
      display: flex;
      margin-top: 20px;
      align-items: center;

      button{
        flex-shrink: 0;
        margin-left: 10px;
      }

      select{
        padding: 12px 20px;
        margin-left: 10px;
      }

      .risk-icon{
        color: $creditlife-green;
        font-size: 24px;

        svg{
          display: block;
        }

        img{
          max-height: 24px;
          display: block;
        }
      }
    }
  }
}
