.char-counter {
    margin-top: 10px;
    font-family: 'Frutiger', sans-serif;
}

.button-container {
    display: flex;
    justify-content: flex-start; // Align buttons to the start of the container
    align-items: center;
    margin-top: 1rem; // Adjust as needed

    // Set the flex container to no wrap to prevent buttons from wrapping to the next line
    flex-wrap: nowrap;

    // Target your buttons with specific classes if necessary
    .btn {
        // Remove any margin or padding that might cause overlap
        margin-right: 10px; // Add space between buttons

        &:last-child {
            margin-right: 0; // Ensure the last button has no extra margin
        }
    }

    // If buttons are still overlapping, adjust padding inside the buttons
    .btn-green {
        padding-left: 10px;
        padding-right: 10px;
    }
}
