@import '../../colors';

.client-form{
  .client-form-panel{
    h1, label{
      color: #414140;
      font-weight: 300;
      font-size: 24px;
      margin: 0;
      padding: 0;
      line-height: 150%;
    }

    &.error {
      border: 3px solid $light-red;
    }

    label{
      margin-bottom: 20px;
      display: block;

      &.small{
        margin-bottom: 10px;
        font-size: 14px;
        line-height: 14px;
        margin-top: 20px;
      }
    }

    margin-bottom: 30px;

    input[type=text], input[type=number]{
      padding: 20px 25px 17px 25px;
      font-size: 16px;
    }

    .buttons-group{
      display: flex;
      flex-wrap: wrap;
      margin-bottom: -20px;
    }

    .children-input-group{
      position: relative;

      button{
        background: #F45051;
        color: white;
        width: 20px;
        height: 20px;
        border-radius: 20px;
        display: flex;
        align-content: center;
        justify-content: center;
        font-size: 12px;
        padding: 0;
        position: absolute;
        top: -5px;
        right: -5px;
      }
    }
  }
}

.progress-bar{
  background: #E8E8E8;
  height: 8px;

  .progress-bar-progress{
    height: 8px;
    background: $creditlife-green;
    transition: 0.4s ease all;
  }
}

.client-form-loader{
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 180px;
  font-size: 28px;
  color: #414140;
  font-weight: 300;
  margin-bottom: 250px;

  svg{
    display: block;
    font-size: 160px;
    color: $creditlife-green;
    margin-bottom: 30px;
  }
}
