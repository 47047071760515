@import '../../colors';

.invitations-view {
  .used-chip {
    border-radius: 4px;
    background-color: $grey-100;
    color: $grey-600;
    display: flex;
    align-items: center;
    gap: 5px;
    width: fit-content;
    font-size: $font-size-body-small;
    padding: 3px 8px;

    svg {
      width: 14px;
      height: 14px;
    }

    &.used {
      background-color: $green-20;
      color: $green-700;
    }
  }

  .delete-invitation {
    padding: 0 !important;
    min-height: unset;
    font-size: $font-size-body-small !important;

    svg {
      width: 16px;
      height: 16px;
      margin-right: 0;
    }

    &:hover {
      background: transparent !important;
      color: $red !important;
    }
  }
}

.new-faq-dialog {
  .MuiDialogTitle-root {
    letter-spacing: 0;
    font-size: $font-size-h3;
    font-weight: bold;
    color: $grey-700;
  }

  .warning-text {
    margin-top: 10px;
    font-size: 14px;
    color: red;
    line-height: 1.4;
    font-weight: bold;
    display: inline-block;
  }
}
