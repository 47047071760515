@import '../../colors';

.select-toggle {
  width: 200px;
  height: 46px;
  background-color: #EFEFEF;
  border-radius: 50px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;

  .MuiSelect-select {
    background-color: #EFEFEF;
    border-radius: 50px;
    height: 46px;
    padding: 0 20px;
    color: #606060;
    display: block;
    width: 100%;
  }

  .MuiSelect-icon {
    right: 20px;
  }

  // Target the root of the Select component if additional styling is needed
  .MuiSelect-root {
    padding: 0;
  }
}

.data-dashboard-view {
  .period {
    box-shadow: none;
    background-color: #EFEFEF;
  }

  .period-date {
    background-color: #EFEFEF;
    box-shadow: none;
    padding: 14px 18px;
  }

  .heading-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .heading-left {
    margin: 0;
  }

  .download-button {
    margin-left: auto;
  }

  .period-control {
    display: flex;
    width: 200px;
    justify-content: space-between;
    background-color: #EFEFEF;
    border-radius: 50px;
    align-items: center;
    font-size: 14px;
    color: #606060;

    button {
      padding: 0 !important;
      width: 46px !important;
      height: 46px;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        color: white;
        font-size: 16px;
        margin-top: 0;
      }
    }
  }
}

.dashboard-panel {
  border-radius: 10px;
  background: #FFF;
  box-shadow: 7px 7px 25px 0px rgba(0, 0, 0, 0.10);
  overflow: hidden;

  .pagination{
    padding: 20px;
    padding-bottom: 10px;
  }

  .search-input{
    background: rgba(0,0,0,0.05);
    box-shadow: none;
    border-radius: 0;
    font-size: 14px;
    padding: 13px 30px 11px 30px;
  }

  .panel-header{
    display: flex;
    align-items: center;
    background: $creditlife-green;
    padding: 15px 30px 12px 30px;

    svg{
      color: white;
      font-size: 20px;
      margin-top: -3px;
      margin-right: 10px;
    }

    h2{
      margin: 0;
      color: white;
      font-size: 20px;
    }
  }

  .panel-content{
    padding: 30px;

    h3{
      margin: 0;
      color: #414140;
      font-size: 17px;
    }

    select{
      background-image: linear-gradient(45deg, transparent 50%, rgba(0,0,0,0.4) 50%),
      linear-gradient(135deg, rgba(0,0,0,0.4) 50%, transparent 50%);
      background-color: #EFEFEF;
      box-shadow: none;
      border-radius: 50px;
    }

    hr{
      border: 0;
      border-top: 1px solid #ddd;
      margin: 20px 0 30px 0;
    }
  }

  &.compact{
    .search-input{
      background: rgba(0,0,0,0.05);
      box-shadow: none;
      border-radius: 0;
      font-size: 13px;
      padding: 13px 12px 10px 12px;
    }

    .panel-header{
      padding-left: 15px;
    }
    .panel-content {
      padding: 0;
    }
    p{
      padding: 20px 16px;
    }
    .MuiCircularProgress-root{
      padding: 20px 16px;
      color: $creditlife-green;
    }
  }
  .MuiCircularProgress-root{
    color: $creditlife-green;
  }
}

.dashboard-container {
  .MuiMasonry-root {
    width: auto;
  }
}

// OLD CSS

//.dashboard-container {
//    //display: flex;
//    //justify-content: space-around;
//    //padding: 20px;
//    //flex-wrap: wrap; // Allows wrapping for smaller screens
//
//    .section {
//        //width: 45%; // Adjust the width as needed
//        //margin: 10px;
//        border-radius: 10px;
//        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//        padding: 20px;
//        background-color: white;
//
//        h2 {
//            margin-top: 0;
//            color: #333;
//        }
//
//        &.user-section {
//            // User-specific styles
//            border: 2px solid #007bff; // Blue border for user section
//            background-color: #f0f8ff; // Light blue background
//
//            h2 {
//                color: #007bff; // Blue heading
//            }
//        }
//
//        &.client-section {
//            // Client-specific styles
//            border: 2px solid #28a745; // Green border for client section
//            background-color: #f0fff0; // Light green background
//
//            h2 {
//                color: #28a745; // Green heading
//            }
//        }
//    }
//}
