@import '../../../../colors';

.test-cases-table-view {
  .backoffice-search {
    background: white;
    border: 1px solid $grey-400;
    border-radius: 8px;
    padding: 8px 16px;
    font-size: $font-size-body-middle;
    color: $grey-700;
    width: 300px;

    &::placeholder {
      color: $grey-600;
    }
  }

  .table-cell {
    font-size: $font-size-body-middle;
    color: $grey-700;
    max-width: 300px; // Limit width for question/answer columns
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .light {
    color: $grey-600;
    font-size: $font-size-body-middle;
  }

  .table-row {
    &:hover {
      background-color: $grey-50;
    }

    td {
      padding: 16px;
      border-bottom: 1px solid $grey-200;
    }
  }

  .table-header {
    th {
      background-color: $grey-50;
      color: $grey-700;
      font-weight: 600;
      padding: 12px 16px;
      border-bottom: 1px solid $grey-200;
    }
  }

  .backoffice-table {
    background: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    margin-bottom: 16px;
    overflow: hidden;
  }

  .btn {
    padding: 8px 16px;
    border-radius: 8px;
    font-size: $font-size-body-middle;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    transition: background-color 0.2s;

    &.btn-green {
      background-color: $green-600;
      color: white;

      &:hover {
        background-color: $green-700;
      }
    }

    &.btn-ghost {
      background: transparent;
      color: $grey-700;
      border: 1px solid $grey-400;

      &:hover {
        background: $grey-50;
      }
    }
  }
}
