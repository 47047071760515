@import '../../colors';

.chat-backoffice-view {
  min-height: calc(100vh - 200px);
  font-family: 'Frutiger', sans-serif;

  .current-file-container {
    position: relative;

    .btn-delete {
      position: absolute;
      top: -10px;
      right: -10px;
    }
  }

  .current-file {
    widht: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    aspect-ratio: 1;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 7px 7px 25px 0px rgba(0, 0, 0, 0.10);
    font-size: 14px;
    color: #606060;
    cursor: pointer;

    img {
      height: 70px;
      margin-bottom: 20px;
    }
  }

  .dropzone {
    background: rgba(0, 0, 0, 0.05);
    height: calc(100% - 20px);
    padding: 20px;
    box-sizing: border-box;
    border: 1px dashed #CCC;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    .dropzone-file {
      display: flex;
      align-items: center;
      border-radius: 5px;
      background: #FFF;
      box-shadow: 7px 7px 25px 0px rgba(0, 0, 0, 0.10);
      padding: 10px;
      font-size: 14px;
      color: #606060;
      position: relative;
      overflow: hidden;
      box-sizing: border-box;

      img {
        height: 30px;
        margin-right: 10px;
      }

      .overlay {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        background: rgba(0, 0, 0, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 20;
        opacity: 0;
        transition: 0.2s ease all;

        svg {
          color: white;
        }
      }

      &:hover {
        .overlay {
          opacity: 1;
        }
      }
    }
  }

  h4 {
    margin-bottom: 10px;
    margin-top: 30px;
    color: #414140 !important;
  }

  input, textarea {
    font-family: "Frutiger", sans-serif;
    width: 100%;
    background: rgba(0, 0, 0, 0.05);
    border: 0;
    padding: 15px 20px;
    border-radius: 5px;
    box-sizing: border-box;
    box-shadow: none;
  }

  textarea {
    padding: 20px;
  }

  .helper {
    font-size: 12px;
    color: #999;
    display: block;
    margin-top: 10px;

    &.error {
      color: red;
    }
  }

  .moderation-container {
    border: 1px dashed #CCC;
    background: rgba(0, 0, 0, 0.02);
    border-radius: 10px;
    padding: 20px;
    margin-top: 30px;

    .MuiFormControlLabel-label {
      font-size: 14px;
      color: #606060;
    }
  }
}
