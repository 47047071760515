@import '../../../colors';

.reviews {
  .review {
    display: flex;
    gap: 10px;

    p {
      font-size: $font-size-body-middle;
      color: $grey-700;
      font-weight: normal;
      margin-bottom: 8px;
      margin-top: 3px;

      b {
        color: $green-700;
      }

      small {
        font-size: $font-size-body-small;
        color: $grey-400;
      }

      .comment {
        display: block;
        margin-top: 8px;
      }
    }

    h4 {
      font-size: $font-size-body-middle;
      color: $grey-700;
      margin: 0;
      padding-top: 6px;
      margin-bottom: 20px;

      &::first-letter {
        text-transform: uppercase
      }
    }

    button {
      flex-grow: 1;
    }

    .timeline-node {
      position: relative;
      width: 28px;
      flex-shrink: 0;

      .node {
        width: 28px;
        height: 28px;
        border: 1px solid $grey-200;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: white;
        z-index: 2;
        position: relative;
        color: $grey-700;

        svg {
          height: 16px;
          width: 16px;
        }
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        height: 100%;
        border-left: 1px dashed $grey-200;
      }
    }

    &.approved {
      .timeline-node {
        &:before {
          border-left: 1px solid $green-500;
        }

        .node {
          color: white;
          background: $green-500;
          border-color: $green-500;
        }
      }
    }

    &.rejected {
      .timeline-node {
        .node {
          background: $red;
          color: white;
          border-color: $red;
        }
      }
    }

    &.simple {
      .timeline-node {
        .node {
          background: white;
          border-color: white;
          color: $grey-400;
        }
      }
    }

    &.second {
      .timeline-node {
        &::before {
          border-color: white;
        }
      }
    }

    .separator {
      width: 100%;
      position: relative;
      text-align: center;
      color: $grey-700;
      font-size: $font-size-body-middle;
      margin: 10px 0;

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        height: 1px;
        width: calc(50% - 20px);
        background: $grey-100;
      }

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        height: 1px;
        width: calc(50% - 20px);
        background: $grey-100;
      }
    }
  }
}

.rejection-popover .MuiPopover-paper {
  margin-left: -20px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  border: 1px solid $grey-200;
  width: 400px;

  .popover-tile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid $grey-200;

    .close {
      padding: 0;
      background: transparent;
      border: none;
      color: $grey-700;
      cursor: pointer;
      svg {
        display: block;
      }
    }

    h4 {
      margin: 0;
      font-size: $font-size-h2;
      color: $grey-700;
    }
  }

  .popover-content {
    padding: 20px;
  }
}
