@import '../../../../colors';

.faq-view {
  h1 {
    color: $grey-700;
    font-size: $font-size-h1;
    margin: 0;
    margin-right: 10px;
  }

  .header-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header-row-left {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .featured-button {
      width: 40px;
      height: 40px;
      border-radius: 4px;
      color: $grey-700;

      svg {
        width: 18px;
      }

      &:hover {
        background-color: $grey-100;
      }

      &.featured {
        color: $orange;
      }
    }
  }

  .created-info {
    color: $grey-600;
    font-size: $font-size-body-middle;
    margin-bottom: 30px;
    display: block;
  }

  .warning-text {
    margin-top: 10px;
    font-size: 14px;
    color: $red;
    line-height: 1.4;
    font-weight: bold;
    display: inline-block;
  }
}
