@import '../../colors';

.radio-checkbox-button{
  font-family: "Frutiger", sans-serif;
  padding: 10px 25px 7px 7px;
  border-radius: 10px;
  border: 2px solid #CECECE;
  display: flex;
  align-items: center;
  color: #606060;
  font-size: 14px;
  cursor: pointer;
  transition: 0.3s ease all;
  padding-right: 25px;

  &.full-width{
    width: 100%;
  }

  &:not(.full-width){
    margin-right: 20px;
    margin-bottom: 20px;
  }

  .radio-checkbox-button__indicator{
    width: 25px;
    height: 25px;
    background: #D9D9D9;
    border-radius: 5px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    margin-top: -3px;
  }

  &:focus {
    box-shadow: 7px 7px 25px 0px rgba(0, 0, 0, 0.1);
    border-color: white;
    background: white;

    .radio-checkbox-button__indicator {
      background: lighten(#D9D9D9, 5%);
    }
  }

  &.selected{
    background: $creditlife-green;
    border-color: $creditlife-green;
    color: white;
    font-weight: bold;

    .radio-checkbox-button__indicator{
      background: white;
      color: $creditlife-green;
    }
  }
}
