@import '../../../../colors';

.testcase-new-view {
  .loading-backdrop {
    color: #fff;
    z-index: 1301;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    h1 {
      color: $grey-700;
      font-size: $font-size-h2;
      font-weight: 600;
      margin: 0;
    }

    .actions {
      display: flex;
      gap: 16px;
    }
  }

  .testcase-form {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

    .form-header {
      padding: 24px;
      border-bottom: 1px solid $grey-100;

      h2 {
        color: $grey-700;
        font-size: $font-size-h3;
        font-weight: 600;
        margin: 0;
      }
    }

    .form-content {
      padding: 24px;

      .form-group {
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 0;
        }

        label {
          display: block;
          color: $grey-700;
          font-size: $font-size-body-middle;
          font-weight: 500;
          margin-bottom: 8px;

          .required {
            color: $red;
            margin-left: 4px;
          }
        }

        .input-wrapper {
          position: relative;

          textarea {
            width: 100%;
            min-height: 120px;
            padding: 12px;
            border: 1px solid $grey-400;
            border-radius: 8px;
            font-size: $font-size-body-middle;
            color: $grey-700;
            resize: vertical;
            transition: border-color 0.2s;

            &:focus {
              outline: none;
              border-color: $green-500;
            }

            &::placeholder {
              color: $grey-600;
            }
          }
        }

        .answer-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 8px;

          .generate-button {
            height: 36px;
            padding: 0 16px;
            background: $green-20;
            color: $green-700;
            border: 1px solid $green-500;
            border-radius: 6px;
            font-size: $font-size-body-middle;
            font-weight: 500;
            display: flex;
            align-items: center;
            gap: 8px;
            cursor: pointer;
            transition: all 0.2s;

            &:hover {
              background: $green-10;
            }

            &:disabled {
              background: $grey-100;
              border-color: $grey-200;
              color: $grey-400;
              cursor: not-allowed;
            }

            svg {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }
  }

  // Button styles
  .btn {
    height: 40px;
    padding: 0 20px;
    border-radius: 6px;
    font-size: $font-size-body-middle;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
    transition: all 0.2s;

    &.btn-ghost {
      background: transparent;
      color: $grey-700;
      border: 1px solid $grey-400;

      &:hover {
        background: $grey-50;
      }
    }

    &.btn-green {
      background: $green-600;
      color: white;
      border: none;

      &:hover {
        background: $green-700;
      }

      &:disabled {
        background: $grey-400;
        cursor: not-allowed;
      }
    }

    svg {
      width: 18px;
      height: 18px;

      &.loader {
        animation: spin 1s linear infinite;
      }
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
