.additional-text-view {
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 92vh;
  padding: 20px;
  box-sizing: border-box;

  .content-container {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 30px;
    max-width: 600px;
    width: 100%;
    text-align: center;
  }

  h1 {
    color: #222;
    font-size: 28px;
    margin-bottom: 20px;
  }

  p {
    font-size: 18px;
    line-height: 1.6;
    color: #555;
    white-space: pre-wrap;
    margin: 0;
  }
}
