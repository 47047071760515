@import '../../../../colors';

.faq-view {
  tr {
    cursor: pointer;
  }

  .featured-icon {
    color: $grey-600;
    width: 18px;
    display: block;
  }

  .featured-btn {
    width: 26px;
    height: 26px;
    padding: 0;

    svg {
      color: $grey-600;
      width: 16px;
      height: 16px;
    }

    &:hover {
      background: $grey-200;
    }

    &.active {
      svg {
        color: $orange;
      }
    }

    .MuiTouchRipple-root {
      display: none;
    }
  }
}

.new-faq-dialog {
  .MuiDialogTitle-root {
    letter-spacing: 0;
    font-size: $font-size-h3;
    font-weight: bold;
    color: $grey-700;
  }

  .warning-text {
    margin-top: 10px;
    font-size: 14px;
    color: red;
    line-height: 1.4;
    font-weight: bold;
    display: inline-block;
  }
}
