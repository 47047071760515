@import '../../colors';

.action-button {
  background: $creditlife-green;
  border: 0;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  color: white;
  font-size: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.red {
    background: rgb(244, 80, 81);
  }
}
