@import '../../../colors';

.conversation-message {
  width: 100%;
  position: relative;

  &:not(:first-child) {
    margin-top: 16px;
  }

  .message-content {
    box-sizing: border-box;
    width: 85%;
    padding: 10px;
    background: white;
    border-radius: 10px;

    &.user {
      border-bottom-left-radius: 0;
    }

    &.assistant {
      justify-self: flex-end;
      border-bottom-right-radius: 0;
    }

    .message-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .author {
        font-size: $font-size-body-middle;
        color: $green-700;
        display: block;
        font-weight: bold;
      }

      .time {
        color: $grey-400;
        display: block;
        font-size: $font-size-body-small;
        font-weight: 300;
      }
    }

    p {
      color: $grey-700;
      font-size: $font-size-body-middle;
      font-weight: 300;
      margin: 0;
      margin-top: 4px;
    }

    .sources {
      display: flex;
      align-items: center;
      margin-top: 8px;
      cursor: pointer;

      .sources-count {
        background: $green-700;
        width: 15px;
        height: 15px;
        color: white;
        text-align: center;
        line-height: 15px;
        font-size: 10px;
        font-weight: bold;
        border-radius: 20px;
        flex-shrink: 0;
        margin-right: 8px;
      }

      .sources-icon {
        background: $green-20;
        border-radius: 8px;
        width: 28px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;

        svg {
          color: $green-600;
          width: 16px;
        }
      }

      .sources-text {
        font-size: $font-size-body-small;
        margin-left: 5px;
      }

      .sources-caret {
        width: 16px;
        color: $grey-500;
        margin-left: 5px;
      }
    }

    .source {
      background: $green-20;
      margin-top: 10px;
      border-radius: 8px;
      padding: 8px;
      display: flex;
      align-items: center;
      gap: 10px;
      text-decoration: none;
      cursor: pointer;

      .icon {
        width: 30px;
        height: 30px;
        background: $green-20;
        border-radius: 8px;
        color: $green-800;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;

        svg {
          height: 16px;
        }
      }

      h4 {
        margin: 0;
        font-size: $font-size-body-small;
        color: $green-700;
      }
    }
  }

  .satisfaction {
    position: absolute;
    bottom: 0;
    right: 85%;
    padding-right: 10px;

    &.unevaluated {
      color: $grey-400;
    }

    &.satisfied {
      color: $green-700;
    }

    &.dissatisfied {
      color: $red;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .alert-icon {
    display: none;
  }

  &.alert-message {
    .message-content {
      background: $yellow-10;
    }

    .alert-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 85%;
      padding-right: 10px;

      &.user {
        right: unset;
        left: 85%;
        padding-right: 0;
        padding-left: 10px;
      }

      svg {
        width: 20px;
        height: 20px;
        color: $orange;
      }
    }
  }
}
