.backoffice-navbar{
  background: #E3E3E3;
  padding: 13px 0 10px 0;

  .backoffice-navbar-menu{
    .backoffice-navbar-menu-item{
      margin-right: 30px;
      font-size: 12px;
      font-weight: bold;
      text-decoration: none;
      color: #606060;
      display: inline-flex;
      align-items: center;
      line-height: 17px;
      position: relative;
      cursor: pointer;

      svg{
        margin-top: 0;
        vertical-align: text-bottom;
        margin-right: 5px;
        width: 15px;
        height: 15px;
      }

      .backoffice-navbar-menu-children {
        position: absolute;
        top: 25px;
        left: 0;
        background: white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        padding: 15px;
        visibility: hidden;
        opacity: 0;
        transition: 0.2s ease all;
        z-index: 4;

        .backoffice-navbar-menu-child {
          text-wrap: nowrap;
          display: block;
          text-decoration: none;
          color: #606060;

          &:not(:last-child) {
            padding-bottom: 10px;
            border-bottom: 1px solid rgba(0,0,0,0.05);
          }

          &:not(:first-child) {
            padding-top: 10px;
          }
        }
      }

      &:hover {
        .backoffice-navbar-menu-children {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}
