@import '../../../colors';

.topic-selection-modal {
  .MuiDialog-paper {
    border-radius: 16px;
    border: 1px solid $grey-200;

    .MuiDialogTitle-root {
      padding: 20px;
      font-size: $font-size-h2;
      color: $grey-700;
      font-weight: bold;
      line-height: $font-size-h2;
      border-bottom: 1px solid $grey-200;
    }

    .MuiDialogContent-root {
      padding: 20px;

      .custom-checkbox {
        font-size: $font-size-body-middle;
        font-weight: bold;
      }

      p {
        color: $grey-500;
        font-weight: normal;
        font-size: $font-size-body-small;
        margin-bottom: 20px;
      }
    }
  }
}
