@import '../../colors.scss';

img {
  color: $creditlife-green;
}

//@import '../../colors';
//
//.case-study-detail {
//
//    .left-column {
//        border-radius: 20px;
//        background-image: url('../../../public/bubbles.jpg');
//        background-size: cover;
//        background-position: bottom;
//        box-shadow: 7px 7px 25px 0px rgba(0, 0, 0, 0.10);
//        height: calc(100% - 50px);
//        z-index: 1;
//        position: relative;
//        display: flex;
//        flex-direction: column;
//        justify-content: flex-end;
//        padding: 30px;
//        text-align: center;
//        min-height: 60vh;
//
//        p {
//            a {
//                color: $creditlife-green;
//                text-decoration: none;
//                font-weight: bold;
//            }
//        }
//
//        img {
//            max-height: 40px;
//            margin-top: 30px;
//        }
//
//        a.small {
//            color: #606060;
//            font-size: 10px;
//            display: block;
//            text-decoration: none;
//            margin-top: 7px;
//        }
//    }
//
//    .header {
//        width: calc(100% - 80px);
//        margin-left: 30px;
//        padding: 20px;
//        background: $creditlife-green;
//        box-shadow: 7px 7px 25px 0px rgba(0, 0, 0, 0.10);
//        border-radius: 10px;
//        display: flex;
//        justify-content: space-between;
//        align-items: center;
//        margin-bottom: 20px;
//        z-index: 2;
//        position: relative;
//
//        .case-study-info {
//            display: flex;
//            align-items: center;
//
//            .image {
//                width: 70px;
//                height: 70px;
//                border-radius: 50%;
//                box-shadow: 7px 7px 25px 0px rgba(0, 0, 0, 0.10);
//                background-size: cover;
//                background-position: center;
//                margin-right: 20px;
//            }
//
//            h1 {
//                color: white;
//                margin: 0;
//                font-size: 24px;
//            }
//
//            h2 {
//                margin: 0;
//                color: rgba(255, 255, 255, 0.80);
//                font-size: 14px;
//                margin-top: 3px;
//            }
//        }
//    }
//
//    .match-info {
//        background: #FFF;
//        padding: 20px;
//        border-radius: 10px;
//        box-shadow: 7px 7px 25px 0px rgba(0, 0, 0, 0.10);
//
//        .case-details {
//            p {
//                margin: 20px 0;
//
//                svg {
//                    color: $creditlife-green;
//                    vertical-align: middle;
//                    margin-top: -2px;
//                }
//            }
//
//            ul,
//            li {
//                color: inherit;
//                font-size: inherit;
//                font-family: inherit;
//                line-height: inherit;
//            }
//        }
//    }
//}
