/* Add this CSS to style the ScrollableTable component */
.scrollable-table-container {
    max-height: 300px;
    /* Set the desired maximum height */
    overflow-y: auto;
    /* Add a vertical scrollbar when content overflows */
}

.scrollable-table {
    width: 100%;
    /* Ensure the table takes full width */
    border-collapse: collapse;
    /* Optional: Remove spacing between table cells */
}

/* Style the table headers and cells as needed */
.scrollable-table th,
.scrollable-table td {
    border: 1px solid #ddd;
    /* Optional: Add borders to table cells */
    padding: 8px;
    /* Optional: Add padding to table cells */
    text-align: left;
    /* Optional: Align text as needed */
}

/* Optional: Add more styling to table headers and cells */
