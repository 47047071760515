@import '../../colors';

.email-link {
  color: $creditlife-green;
  font-weight: bold;
}

.signup-form{
  input[type="checkbox"]{
    display: none;
  }

  input[type="checkbox"] {
    & + label.terms-field {
      padding-left: 30px;
      position: relative;
      color: #606060;
      font-weight: 400;
      font-size: 12px;
      line-height: 150%;
      margin-top: 30px;
      padding-top: 2px;

      &:before {
        content: '✔';
        text-align: center;
        background: white;
        border-radius: 5px;
        box-shadow: 7px 7px 25px 0px rgba(0, 0, 0, 0.10);
        width: 20px;
        height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        color: white;
        transition: 0.2s ease all;
        font-size: 12px;
        line-height: 23px;
      }

      a {
        text-decoration: none;
        color: $creditlife-green;
        font-weight: 500;
        font-weight: bold;
      }
    }

    &:checked + label.terms-field{
      &:before{
        color: white;
        background: $creditlife-green;
      }
    }
  }
}
