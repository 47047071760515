@import '../../colors';

.profile-settings{
  label{
    color: #414140;
    font-size: 13px;
    display: block;
    margin-bottom: 5px;

    &:not(:first-child){
      margin-top: 20px;
    }
  }

  .user-heading{
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .profile-img svg{
      color: $creditlife-green;
      height: 40px;
      width: 40px;
      margin-right: 10px;
    }

    h1{
      color: #999;
      font-weight: 300;
      font-size: 22px;
      margin: 0;

      b{
        font-weight: bold;
        color: #606060;
      }
    }
  }
}
