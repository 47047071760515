@import '../../../colors';

.form-input {
  display: block;
  width: 100%;
  box-sizing: border-box;
  font-size: $font-size-body-middle;
  font-weight: 300;
  color: $grey-700;
  border: 1px solid white;
  outline: 1px solid $grey-400;
  border-radius: 8px;
  padding: 14px 16px;
  transition: 0.2s ease all;

  &::placeholder {
    color: $grey-500;
  }

  &:hover {
    outline: 1px solid $green-500;
  }

  &:focus {
    border: 1px solid $green-500 !important;
    outline: 1px solid $green-500 !important;
  }

  &.error {
    border: 1px solid white !important;
    outline: 1px solid $red !important;

    &:focus {
      border: 1px solid $red !important;
      outline: 1px solid $red !important;
    }
  }
}

.form-input-limit {
  color: $grey-700;
  display: block;
  font-size: $font-size-body-small;
  margin-top: 5px;
}
