@import '../../colors';

.chat-view {
  margin-top: 60px;
  margin-bottom: 60px;

  .faq {
    padding: 30px;
    background-color: $grey-50;
    border-radius: 16px;
    height: calc(100vh - 250px);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .content {
      flex-grow: 1;
      overflow-y: scroll;
    }

    button {
      flex-shrink: 0;
    }

    .title {
      display: flex;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;

      .icon {
        color: $green-500;
        height: 30px;
        width: 30px;
        border-radius: 8px;
        background: $green-20;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 15px;
          height: 15px;
        }
      }

      h1 {
        font-size: $font-size-h3;
        color: $grey-700;
        margin: 0;
      }
    }

    .faq-question {
      font-family: "Frutiger", sans-serif;
      width: 100%;
      padding: 15px;
      padding-bottom: 13px;
      border: 0;
      text-align: left;
      box-sizing: border-box;
      background: white;
      border-radius: 5px;
      font-size: 13px;
      color: #606060;
      cursor: pointer;
      margin-top: 10px;
    }
  }
}

.chat-container {
  color: $grey-700;
  font-size: $font-size-body-middle;
  background: $grey-50;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 250px);
  border-radius: 16px;
  padding: 20px;
  box-sizing: border-box;

  .backoffice-title {
    margin-bottom: 10px;
  }

  .messages {
    flex-grow: 1;
    overflow-y: scroll;
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 30px;

    .placeholder {
      height: 100%;
    }
  }

  .chat-bottom {
    flex-shrink: 0;

    p.disclaimer {
      margin: 0;
      margin-top: 10px;
      font-size: $font-size-body-small;
      color: $red;
      font-weight: bold;
    }
  }

  .chat-input {
    border-radius: 8px;
    display: flex;
    padding: 10px;
    background: white;

    &.disabled {
      opacity: 0.7;
    }

    textarea {
      all: unset;
      flex-grow: 1;
      height: 100%;
      box-sizing: border-box;
      padding: 5px;
    }

    button {
      flex-shrink: 0;
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $green-10;
      transition: 0.2s ease all;
      border-radius: 8px;
      color: $green-500;

      svg {
        width: 20px;
        height: 20px;
      }

      &:hover {
        background: $green-20;
      }

      &:disabled {
        background: $grey-100;
        color: $grey-600;
        cursor: not-allowed !important;
      }
    }
  }
}
