@import '../../colors';

.landing-page {
  background-image: url('../../../public/landing-background.jpg');
  background-size: cover;
  background-position: top;
  min-height: calc(100vh - 50px);
  min-width: 1000px;
  width: 100%;          // Ensures the landing page takes up full width of the viewport
  max-width: 2400px;    // Sets a maximum width for larger screens
  margin: 0 auto;       // Centers the landing page content

  .landing-page-header {
    display: flex;
    justify-content: space-between;
    padding-top: 50px;
    width: 100%;

    img {
      max-height: 40px;
    }
  }

  .landing-page-content {
    margin-top: 47vh;
    max-width: 25em;

    svg {
      font-size: 60px;
      color: $creditlife-green;
    }

    h1 {
      margin: 0;
      color: white;
      font-size: 34px;
    }

    p {
      font-size: 14px;
      font-weight: 300;
      margin: 0;
      color: rgba(255, 255, 255, 0.7);
      margin-top: 20px;
    }
  }
}

// // Media query for additional styling on larger screens
// @media (min-width: 1200px) {
//   .landing-page {
//     // The max-width and margin from above ensure the layout is centered and does not exceed 1600px
//   }
// }
