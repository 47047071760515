@import '../../colors';

.match-item{
  background: white;
  box-shadow: 7px 7px 25px 0px rgba(0, 0, 0, 0.10);
  border-radius: 10px;
  padding: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &:not(:last-child){
    margin-bottom: 30px;
  }

  .match-item-details{
    display: flex;
    align-items: center;

    .match-item-image{
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background-size: cover;
      background-position: center;
      margin-right: 20px;
    }

    .match-details{
      h3{
        font-size: 16px;
        font-weight: bold;
        color: #606060;
        margin: 0;
      }

      h4{
        color: #999;
        font-weight: 500;
        font-size: 12px;
        margin: 0;
        margin-top: 5px;
      }
    }
  }

  .button-group {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .details-button{
    background: $creditlife-green;
    border: 0;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    color: white;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .soft-delete-button {
    background: #F45051;
    border: 0;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    color: white;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 10px;
  }
}
