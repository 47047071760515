@import '../../../colors';

.form-icon-input {
  position: relative;

  input {
    padding-left: 40px;
  }

  svg {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    height: 20px;
    color: $grey-700;
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
