@import '../../../colors';

.chat-dashboard-view {
  .period-date {
    background: white;
    box-shadow: none;
    height: 40px;
    box-sizing: border-box;
    outline: 1px solid $grey-400 !important;
    border: 1px solid white !important;
    border-radius: 8px;
    transition: 0.2s ease all;

    &:hover {
      outline: 1px solid $green-500 !important;
    }

    &:focus {
      border: 1px solid $green-500 !important;
      outline: 1px solid $green-500 !important;
    }
  }

  .heading-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
  }

  .time-controls {
    display: flex;
    gap: 20px;
  }

  .period {
    border-radius: 40px;
    background-color: $grey-50;
    width: auto;
    min-width: 200px;
    box-shadow: none;
    color: $grey-700;
    padding: 0;
    padding-left: 16px;
    height: 40px
  }

  .period-control {
    display: flex;
    width: 200px;
    justify-content: space-between;
    background-color: #EFEFEF;
    border-radius: 50px;
    align-items: center;
    font-size: 14px;
    color: #606060;
  }

  .dashboard-panel {
    border-radius: 16px;
    background: #FFF;
    border: 1px solid $grey-200;
    box-shadow: none;
    overflow: hidden;

    .pagination {
      padding: 20px;
      padding-bottom: 10px;
    }

    .search-input {
      background: rgba(0, 0, 0, 0.05);
      box-shadow: none;
      border-radius: 0;
      font-size: 14px;
      padding: 13px 30px 11px 30px;
    }

    .panel-header {
      display: flex;
      align-items: center;
      padding: 20px;
      background: $grey-50;
      border-bottom: 1px solid $grey-200;

      svg {
        width: 20px;
        height: 20px;
        color: $grey-700;
      }

      h2 {
        margin: 0;
        font-size: $font-size-h2;
        color: $grey-700;
        line-height: 20px;
      }
    }

    .panel-content {
      padding: 20px;

      h3 {
        margin: 0;
        color: #414140;
        font-size: 17px;
      }

      select {
        background-image: linear-gradient(45deg, transparent 50%, rgba(0, 0, 0, 0.4) 50%),
        linear-gradient(135deg, rgba(0, 0, 0, 0.4) 50%, transparent 50%);
        background-color: #EFEFEF;
        box-shadow: none;
        border-radius: 50px;
      }

      hr {
        border: 0;
        border-top: 1px solid #ddd;
        margin: 20px 0 30px 0;
      }
    }

    &.compact {
      .search-input {
        background: rgba(0, 0, 0, 0.05);
        box-shadow: none;
        border-radius: 0;
        font-size: 13px;
        padding: 13px 12px 10px 12px;
      }

      .panel-header {
        padding-left: 15px;
      }

      .panel-content {
        padding: 0;
      }

      p {
        padding: 20px 16px;
      }

      .MuiCircularProgress-root {
        padding: 20px 16px;
        color: $creditlife-green;
      }
    }

    .MuiCircularProgress-root {
      color: $creditlife-green;
    }
  }
}

.dashboard-container {
  .MuiMasonry-root {
    width: auto;
  }
}
