@import '../../../colors';

.options-popover {
  .MuiPaper-root {
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.09) !important;
    min-width: 200px !important;
    padding: 10px;
  }

  .options-popover-search {
    background: $grey-100;
    box-shadow: none;
    font-weight: normal !important;
    font-size: $font-size-body-middle;
    padding: 11px 17px;
    border-radius: 4px;
    margin-bottom: 10px;

    &::placeholder {
      color: $grey-400;
      font-weight: normal !important;
      font-size: $font-size-body-middle;
    }
  }

  small {
    color: $grey-400;
  }

  label.custom-checkbox {
    padding: 8px;
    border-radius: 4px;
    transition: 0.1s ease all;
    margin-bottom: 0 !important;

    &:hover {
      background: $grey-100;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}
