@import "../../../colors";

.table-select-label {
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    width: 16px;
    margin-right: 5px;
    color: $grey-500;
  }

  @keyframes pulse-green {
    0% {
      color: $green-600;
    }
    50% {
      color: $green-500;
    }
    100% {
      color: $green-600;
    }
  }

  .pulsating {
    margin-left: 5px;
    animation: pulse-green 1.5s infinite;
  }

  .chevron {
    width: 18px;
    display: block;
    margin-left: 5px;
  }
}
